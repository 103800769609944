
export default {
    add: 'Create client',
    form: 'Client creation form',
    base: 'Basic information',
    smallQuestionnaire: 'Short questionnaire',
    measures: 'Measurements',
    measurements: {
        view: 'View measurements'
    },
    goals: 'Goals',
    diets: 'Diet Plans',
    viewModes: {
        table: 'Show as table',
        cards: 'Show as cards'
    },
    options:{
        editTags: 'Edit tags',
        editQuestionnaires: 'Edit questionnaires',
        massImport: 'Mass import',
        massExport: 'Mass export'
    },
    empty:{
        title: 'NO CLIENTS FOUND',
        subtitle: 'You have not created any clients yet.',
        addFirst: 'Create your first client'
    },
    statistics: {
        title: 'Client statistics',
        lastUpdate: 'Last updated',
        totalAthletes: 'Total clientele',
        totalDiets: 'Total diet plans for clients',
        period: 'Σύνολο διατροφικών πλάνων πελατών',
        gender: {
            title: 'Gender',
            subtitle: 'Gender breakdown of your clientele'
        },
        customizable: {
            title: 'Statistics of a specified period',
        },
        yearly: {
            title: 'Yearly revenue',
            subtitle: 'Your revenue / appointments of every month for {year} (Note: for the upcoming months, the diagram shows the scheduled appointments revenue).'
        },
        appointment: 'Appointment',
        cancelledAppointment: 'Cancelled appointments',
        appointmentIncome: 'Revenue from appointments',
        cancelledAppointmentIncome: 'Cancelled revenue (from cancelled appointments)',
        income: {
            period: 'Revenue this period',
            cancelled: 'Cancelled revenue this period'
        },
        appointments: {
            title: 'Appointment',
            period: 'Appointments this period',
            cancelled: 'Cancelled appointments this period'
        },
        dietPlans: {
            title: 'Diet plan creation',
            subtitle: 'See the rate of creation of new meal plans compared to last month',
        },
        clients: {
            title: 'New clients',
            subtitle: 'See the rate of client creation compared to last month'
        },
        secondCards: {
            income: 'Monthly revenue up to today',
            forecast: 'Estimated revenue for the rest of the month',
            appointments: 'Appointments this month',
            cancelledAppointments: 'Cancelled appointments this month',
        },
        thisMonth: 'This month',
        lastMonth: 'Previous month'
    }
}