export default {
    training: {
        metaTitle:'Συνεδρία εκπαίδευσης',
    },
    scienceLib: {
        metaTitle: 'Επιστημονική βιβλιοθήκη',
        singleMetaTitle: 'Άρθρο',
        filterByCategory: 'Φιλτράρισμα ανά κατηγορία',
        noResults: 'Δεν βρέθηκαν αποτελέσματα.',
        read: 'Ανάγνωση άρθρου',
        published: 'Δημοσιεύτηκε στην κατηγορία',
        back :'Επιστροφή στη βιβλιοθήκη',
        footer: 'Δημοσιεύτηκε στο athlisis.com | Μια εφαρμογή από διατροφολόγους για διατροφολόγους',
    },
    terms: {
        title: 'Όροι χρήσης',
    },
    faq: {
        title: 'ΣΥΧΝΕΣ ΕΡΩΤΗΣΕΙΣ',
        subtitle: 'Συχνές ερωτήσεις και επεξήγηση βασικών λειτουργιών',
        pal: 'Συντελεστές PAL',
        bmr: 'Βασικός Μεταβολισμός',
        useExercise: 'Προχωρημένη εξίσωση',
        manualKcal: 'Χειροκίνητες θερμίδες',
        manualBMR: 'Χειροκίνητο BMR',
        vegan: 'Vegan',
        fasting: 'Νηστεία',
        carbohydration: 'Υδατανθράκωση',
        supplements: 'Συμπληρώματα',
        content: {
            pal: '        <p class="text-muted">\n' +
                '              Για την εκτίμηση των ενεργειακών αναγκών ασθενών τρεις δυο οι κύριες συνιστώσες: α) η εκτίμηση του βασικού\n' +
                '              μεταβολικού ρυθμού, β) η ενεργειακή κατανάλωση λόγω σωματικής δραστηριότητας και η θερμογένεση λόγω\n' +
                '              κατανάλωσης τροφής.\n' +
                '            </p>\n' +
                '            <p class="text-muted">\n' +
                '              Μετά την εκτίμηση του βασικού μεταβολικού ρυθμού ηρεμίας, οι συνολικές ενεργειακές ανάγκες των ενηλίκων\n' +
                '              υπολογίζονται πολλαπλασιάζοντας τον μεταβολικό ρυθμό ηρεμίας με το\n' +
                '              κατάλληλο επίπεδο φυσικής δραστηριότητας\n' +
                '              (Physical Activity Level, PAL), σύμφωνα με τα παρακάτω (Report of a Joint FAO/WHO/UNU Expert Consultation,\n' +
                '              2001): </p>\n' +
                '            <p class="text-muted"><strong>\n' +
                '              - Καθιστικός ή ελαφρά δραστήριος τρόπος ζωής: PAL: 1,40–1,69<br>\n' +
                '              - Έντονα δραστήριος τρόπος ζωής: PAL: 2,00–2,40<br>\n' +
                '              - Τιμές του PAL > 2,40 είναι δύσκολο να διατηρηθούν για μεγάλο χρονικό διάστημα.\n' +
                '            </strong>\n' +
                '            </p>\n' +
                '            <p class="text-muted">\n' +
                '              Συνοπτικά, τα άτομα που κατατάσσονται στην κατηγορία της ελαφριάς δραστηριότητας ή που είναι λιγότερο\n' +
                '              δραστήρια από τον μέσο όρο, είναι παιδιά ή έφηβοι που\n' +
                '              περνούν πολλές ώρες στο σχολείο ή σε καθιστικές δραστηριότητες, δεν αθλούνται συστηματικά, μετακινούνται\n' +
                '              κυρίως με οχήματα και στις ελεύθερες ώρες τους ασχολούνται με δραστηριότητες, όπως η\n' +
                '              τηλεθέαση, το διάβασμα και ο ηλεκτρονικός υπολογιστής. Αντίθετα, τα άτομα με έντονο τρόπο ζωής ή με\n' +
                '              μεγαλύτερη δραστηριότητα από τον μέσο όρο είναι παιδιά και έφηβοι που περπατούν καθημερινά\n' +
                '              μεγάλες αποστάσεις ή που χρησιμοποιούν ποδήλατο για τη μεταφορά τους, που ασχολούνται για αρκετές ώρες με\n' +
                '              δραστηριότητες\n' +
                '              οι οποίες απαιτούν μεγάλα ποσά ενέργειας, και/ή με αθλήματα που απαιτούν υψηλό επίπεδο σωματικής\n' +
                '              προσπάθειας για αρκετές ώρες, αρκετές ημέρες την εβδομάδα.\n' +
                '            </p>\n' +
                '            <img alt="pal" src="https://edlservices.s3.eu-north-1.amazonaws.com/PAL.jpg"/>',
            bmr: '           <p class="text-muted">\n' +
                '              Υπάρχουν διάφορες εξισώσεις που μας βοηθάνε να υπολογίσουμε τον βασικό μας μεταβολισμό, δηλαδή την\n' +
                '              ενέργεια που χρειαζόμαστε για να πραγματοποιήσουμε τις ακούσιες λειτουργίες του οργανισμού μας (αναπνοή,\n' +
                '              πέψη, καρδιακή λειτουργία κ.ά.) εφόσον βρισκόμαστε ξαπλωμένοι σε ύπτια θέση\n' +
                '            </p>\n' +
                '            <div class="p-3">\n' +
                '              <h4>Miffin</h4>\n' +
                '              <p class="text-muted w-75">\n' +
                '                Για τους μη νοσηλευόμενους ασθενείς η εκτίμηση μεταβολικού ρυθμού ηρεμίας γίνεται με βάση τις\n' +
                '                προαναφερόμενες εξισώσεις (Πίνακας 7.1). Ωστόσο, η εκτίμηση των ενεργειακών αναγκών παχύσαρκων ατόμων\n' +
                '                παραμένει δύσκολη και, σύμφωνα με μελέτες εγκυρότητας, φαίνεται πως οι εξισώσεις Mifflin (Mifflin et\n' +
                '                al., 1990) εκτιμούν με μεγαλύτερη ακρίβεια τον βασικό μεταβολικό ρυθμό παχύσαρκων ατόμων\n' +
                '                (D. C. Frankenfield, Rowe, Smith, & Cooney, 2003).\n' +
                '              </p>\n' +
                '              <p class="text-muted w-75">\n' +
                '                Αν ο ΒΜΙ σας είναι μέχρι και 25 χρησιμοποιήστε την παρακάτω εξίσωση <strong>(Harris & Benedict)</strong>\n' +
                '\n' +
                '              </p>\n' +
                '              <h4>Harris-Benedict</h4>\n' +
                '              <p class="text-muted w-75">\n' +
                '                Πως αξιολογείται: Η εξίσωση αυτή είναι η παλαιότερη που υπάρχει και έχει ελεγχθεί ως προς την ικανότητα\n' +
                '                πρόβλεψης του μεταβολικού ρυθμού ηρεμίας σε εύρη βάρους 25-124,9 kg,\n' +
                '                σε εύρη ύψους 151-200 cm και σε ηλικιακό εύρος 21-70 ετών. Έχει χρησιμοποιηθεί επίσης και για την\n' +
                '                εκτίμηση ενεργειακών αναγκών σε παθολογικές καταστάσεις πολλαπλασιάζοντας με τον\n' +
                '                αντίστοιχο παράγοντα στρες (1,2-1,8 ανάλογα με τη βαρύτητα και της φύσης της νόσου). Η συγκεκριμένη\n' +
                '                εξίσωση δεν έχει μελετηθεί στο βαθμό που απαιτείται σε ηλικιωμένα άτομα, στα οποία\n' +
                '                φαίνεται ότι μάλλον υποεκτιμά το RMR (τόσο σε άνδρες όσο και σε γυναίκες).\n' +
                '              </p>\n' +
                '              <p class="text-muted w-75">\n' +
                '                Η αρχική μορφή της, επίσης, τείνει να υπερεκτιμά γενικά το RMR σε ποσοστό έως και 42%.\n' +
                '              </p>\n' +
                '              <p class="text-muted w-75">\n' +
                '                Η αναθεωρημένη μορφή της φαίνεται ότι εκτιμά το RMR με ακρίβεια ± 14% σε άτομα που βρίσκονται σε καλά\n' +
                '                επίπεδα θρέψης, κάτι που δεν ισχύει στις περιπτώσεις ατόμων με υποθρεψία.\n' +
                '\n' +
                '              </p>\n' +
                '              <p class="text-muted w-75">\n' +
                '                <strong>Χρησιμότητα</strong><br>\n' +
                '                Η εξίσωση Harris-Benedict μπορεί να χρησιμοποιείται κυρίως σε νεαρά ενήλικα άτομα (κάτω των 65 ετών)\n' +
                '                φυσιολογικού σωματικού βάρους και σε κλινικές-παθολογικές καταστάσεις\n' +
                '                χρησιμοποιώντας τον κατάλληλο παράγοντα στρες.\n' +
                '\n' +
                '              </p>\n' +
                '            </div>',
            useExercise: '      <h2 class="pb-2">Προχωρημένη εξίσωση; (χρήση αθλημάτων για υπολογισμό ενέργειας ανά ημέρα)</h2>\n' +
                '            <p class="text-muted">\n' +
                '              Στην παρακάτω λειτουργία αφού την επιλέξετε, πλέον ο προσδιορισμός της ενέργειας δεν θα γίνεται με τις\n' +
                '              εξισώσεις των PAL κατά προσέγγιση, αλλά με την χρήση\n' +
                '              πραγματικών αθλητικών δραστηριοτήτων. </p>\n' +
                '            <p class="text-muted">\n' +
                '              Με βάση το PAL των καθημερινών δραστηριοτήτων, ρυθμίζεται με την μπάρα από το 1-1,39 την γενική\n' +
                '              δραστηριότητα της ημέρας (βάδισμα, πλύσιμο δοντιών, εργασία, καθημερινά απαραίτητες\n' +
                '              λειτουργίες κ.α) και έπειτα μεταφερθείτε στο πεδίο της άσκησης, όπου επιλέγοντας την εκάστοτε ημέρα τι\n' +
                '              άθλημα ή φυσική δραστηριότητα θα πραγματοποιηθεί σε συγκεκριμένο χρόνο\n' +
                '              διάστημα θα γίνει ο υπολογισμός των ημερήσιων ενεργειακών αναγκών για το χτίσιμο του πλήρους,\n' +
                '              εξατομικευμένου διαιτολογίου.\n' +
                '            </p>',
            manualKcal: '            <h2 class="pb-2">Εισαγωγή θερμίδων χειροκίνητα;</h2>\n' +
                '            <p class="text-muted">\n' +
                '              Επιλέγοντας αυτή την λειτουργία το πρόγραμμα θα σας βοηθήσει να δημιουργήσετε ένα εβδομαδιαίο διατροφικό\n' +
                '              πλάνο με βάση των συγκεκριμένων θερμίδων που επιθυμείτε. </p>',
            manualBMR: '      <h2 class="pb-2">Εισαγωγή βασικού μεταβολισμού (BMR) χειροκίνητα;</h2>\n' +
                '            <p class="text-muted">\n' +
                '              Επιλέγοντας αυτή την λειτουργία δεν θα χρειαστεί να επιλέξετε κάποια εξίσωση υπολογισμού για τον βασικό\n' +
                '              μεταβολισμό, αλλά θα γίνει χειροκίνητα, ανάλογα με αποτέλεσμα που έχετε βρει από κάποιο μηχάνημα (πχ\n' +
                '              έμμεση θερμιδομετρία )\n' +
                '            </p>',
            vegan: '            <h2 class="pb-2">Vegan διαιτολόγια </h2>\n' +
                '            <p class="text-muted">\n' +
                '              Κατά αυτήν την λειτουργεία το σύστημα θα σας εμφανίζει τρόφιμα που είναι μονάχα για βίγκαν και αν είχατε\n' +
                '              ένα πρότυπο διατροφικό πλάνο (η οπουδήποτε διατροφικό πλάνο)\n' +
                '              και το εισάγεται, στην περίπτωση που υπάρχει κάποιο μην βίγκαν τρόφιμο,\n' +
                '              θα το διαγράψει εμφανώς με στόχο την αντικατάσταση του</p>',
            fasting: '      <h2 class="pb-2">Νηστεία </h2>\n' +
                '            <p class="text-muted">\n' +
                '              Κατά αυτήν την λειτουργεία το σύστημα θα σας εμφανίζει τρόφιμα που είναι μονάχα για την νηστεία και αν\n' +
                '              είχατε ένα πρότυπο διατροφικό πλάνο (η οπουδήποτε διατροφικό πλάνο) και το εισάγεται,\n' +
                '              στην περίπτωση που υπάρχει κάποιο μην νηστίσιμο τρόφιμο, θα το διαγράψει εμφανώς με στόχο την\n' +
                '              αντικατάσταση του. </p>',
            carbohydration: '  <h2 class="pb-2">Υδατανθράκωση </h2>\n' +
                '            <p class="text-muted">\n' +
                '              Επιλέγοντας την υδατανθράκωση, θα σας εμφανίσει σε ποια ημέρα θα επιθυμούσατε να πραγματοποιηθεί το\n' +
                '              πρωτόκολλο. Το σύγχρονο πρωτόκολλο της υδατανθράκωσης είναι : 10γρ υδατανθράκων /kg\n' +
                '              σωματικού βάρους. Αφού επιλέξετε την ημέρα που θα θέλατε να εφαρμοστεί (μια μέρα πριν το μεγάλο αγώνα ή\n' +
                '              προπόνηση), όταν πάτε για την δημιουργία της ημέρας, οι ενεργειακές και\n' +
                '              μακροθρεπτικές ανάγκες θα είναι έτοιμα\n' +
                '              προσαρμοσμένες με τα σωματομετρικά χαρακτηριστικά του ασκούμενου για την δόμηση του διαιτολογίου. </p>\n' +
                '         ',
            supplements: '     <h2 class="pb-2">Συμπληρώματα </h2>\n' +
                '            <p class="text-muted">\n' +
                '              Αφού επιλέξετε τι άθλημα κάνει ο εκάστοτε πελάτης σας, το athlisis σας κάνει προτάσεις συμπληρωμάτων με\n' +
                '              βάση την διεθνή βιβλιογραφία αθλητικής διατροφής για πριν, κατά την διάρκεια και μετά την άσκηση.\n' +
                '            </p>\n' +
                '            <p class="text-muted">\n' +
                '              Δεν χρειάζεται να τα δώσετε όλα τα συμπληρώματα που προτείνονται, αλλά να επιλέξετε με βάση, την ηλικία,\n' +
                '              τους στόχους, τις απαιτήσεις και την ιδιοσυγκρασία του πελάτη σας.\n' +
                '\n' +
                '            </p>',
        }
    }
}