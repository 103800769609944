export default {
    title: 'Diet plan',
    auto: 'Auto creation',
    previewMacros: 'Preview macronutrients',
    findDislikes: 'Search foods that client dislikes',
    findDislikesDisclaimer: 'Searching is not accurate and might have false-positives (or false-negatives).',
    recipes: 'Diet plan recipes',
    share: 'Send by e-mail / Save as PDF',
    searchFor: 'Search for',
    autoDesc: 'Automatic diet plan creation',
    system: 'This is a system template and you cannot edit this. Copy it to a client or to a new template.',
    copyToTemplateTitle: 'Are you sure you want to save this as a template?',
    copyToTemplateText: 'An exact replica will be created which you can edit individually.',
    copyToAthleteTitle: 'Are you sure you want to copy this diet plan?',
    copyToAthleteText: 'An exact replica will be created which you can edit individually.',
    noCalories: {
        title: 'You have not inserted the calories and the nutrients for this diet plan.',
        cta: 'Complete the equations or edit manually!'
    },
    replaceCustomerFns: 'Replace height/weight with the client\'s data?',
    addToCustomer: 'Add to client',
    pasteWithoutClear: 'Paste without clearing',
    copyDay: 'Copy day',
    pasteDay: 'Paste day',
    clearDay: 'Clear day',
    carbohydrationWarning: 'Carbohydration is enabled!',
    ketosisWarning: 'Ketosis is enabled!',
    copyToTable: 'Copy table (for usage in word, excel)',
    mealType: 'Meal type',
    insulinUnits: 'Insulin units',
    recipeModal: {
        title: 'Recipe list in the diet plan',
        list: 'Recipe list',
        empty: 'There were no recipes found in this diet plan.'
    },
    equations: {
        exerciseWarning: 'Οι θερμίδες που αναγράφονται δεν περιλαμβάνουν τα αθλήματα και αφορούν τον βασικό μεταβολισμό επί 1.2!',
        lowCalIntakeWarning: 'ΠΡΟΣΟΧΗ! Η πρόσληψη ενέργειας δεν πρέπει ποτέ να είναι μικρότερη από τον βασικό μεταβολισμό',
        title: 'Equations',
        bmr: 'Base Metabolism Rate (kcal)',
        kcal: 'Required energy (kcal)',
        exerciseLevels: {
            title: 'Επιλέξτε επίπεδο άσκησης',
            '13': '(1.00-1.39) Τυπικές καθημερινές δραστηριότητες (Οικιακά καθήκοντα, χαλαρό περπάτημα,\n' +
                '                  καθιστική εργασία)',
            '155': '(1.4-1.59) Χαμηλή δραστηριότητα, τυπικές καθημερινές δραστηριότητες και 30-60 λεπτά\n' +
                '                  καθημερινής μέτριας δραστηριότητας (περπάτημα με 5-7 χλμ. / Ώρα)',
            '17': '(1.6-1.89) Ενεργοί άνθρωποι, τυπικές καθημερινές δραστηριότητες συν 60 λεπτά\n' +
                '                  καθημερινής μέτριας δραστηριότητας',
            '19': '(1.9-2.5) Πολύ δραστήριοι, τυπικές καθημερινές δραστηριότητες συν τουλάχιστον 60\n' +
                '                  λεπτά καθημερινής μέτριας δραστηριότητας συν επιπλέον 60 λεπτά έντονης ή 120 λεπτά μέτριας\n' +
                '                  δραστηριότητας'
        },
        pal: {
            title: 'Manual PAL',
            range: 'Range: from {from} to {to}'

        },
        placeholder: {
            age: 'Insert the client\'s age',
            height: 'Insert the client\'s height in cm',
            weight: 'Insert the client\'s weight in kg',
            fat: 'Insert the client\'s fat in percentage (e.g. 10%)',
            fatPerKg: 'Fat per body weight kg',
            proteinsPerKg: 'Proteins per body weight kg',
            carbsPerKg: 'Carbs per body weight kg'
        },
        errors: {
            age: 'Age must be valid',
            height: 'Height must be valid',
            weight: 'Weight must be valid',
            fat: 'Fat percentage must be valid',
        },
        settings: {
            title: 'Settings',
            manualKcal: 'Manually kcal insertion?',
            advancedEq: 'Advanced equation? (Use exercise to define daily caloric intake)',
            manualBmr: 'Insert BMR manually?',
            exerciseFactor: 'Calculate caloric needs using exercise factor?',
        },
        goal: {
            title: 'Choose a goal',
            maintain: 'Maintain weight',
            add: 'Lose weight',
            lose: 'Add weight',
            kgLose: 'How many kgs to lose per month?',
            kgAdd: 'How many kgs to add per month?'
        },

        functions: {
            title: 'Choose equation',
            1: 'Harris & Benedict',
            2: 'Revised Harris & Benedict',
            3: 'Owen',
            4: 'Miffin',
            5: 'Katch-McArdle',
            6: 'Cunningham',
            7: 'FAO/WHO/UNU'
        }
    },
    macroTab: {
        title: 'Macronutrients allocation',
        track: 'Enable tracking of micronutrients?',
        choose: 'Please select which micronutrients you wish to track in the meal modal.',
        general: 'General micronutrients',
        trixopoulou: 'Micronutrients only in Trixopoulou food list',
        usda: 'Micronutrients only in USDA food list',
    },
    exerciseTab: {
        title: 'Exercise',
        warning: 'To choose sports, you have to enable the advanced equation.',
        warningCta: 'Change settings'
    },
    settingsTab: {
        title: 'Settings'
    },
    analysisTab: {
        title: 'Analysis'
    },
    notesTab: {
        insertToNotes: 'Add to notes',
        replaceContent: 'Replace content?',
        intro: 'Add notes to this diet plan which you can use during printing.',
    },
    settings: {
        optionsVsDays: 'View <strong>options</strong> instead of days',
        mobileShare: 'Share to mobile app',
        newFreeType: 'Enable option-based free type',
        isVegan: 'Vegan plan',
        isFasting: 'Fasting plan',
        isKeto: 'Keto plan',
        isInsulin: 'Ινσουλινοεξαρτώμενος διαβήτης',
        insulinRatio: 'Εισάγετε την αναλογία υδατάνθρακα για κάθε μονάδα ινσουλίνης. Π.χ. για 1:15 εισάγετε 15',
        insulinRatioWarning: 'Μην ξεχάσετε να ρυθμίσετε την αναλογία ινσουλίνης:υδατάνθρακα.\n' +
            '                  Συμβουλευτείτε τις οδηγίες του ιατρού του διαιτώμενου σχετικά με την αναλογία. Μπορείτε να μάθετε\n' +
            '                  περισσότερες πληροφορίες στις οδηγίες για διατροφολόγους του Pathisis.',
        carbohydration: 'Υδατανθράκωση',
        dayOfCarbohydration: 'Επιλέξτε ημέρα υδατανθράκωσης',
        carbohydrationMissingWeight: 'Δεν έχετε εισάγει το βάρος του πελάτη ώστε να λειτουργήσει ορθά η υδατανθράκωση.',
        carbohydrationMissingWeightCta: 'Κάντε κλικ εδώ για να το εισάγετε!',
        ketoSetup: 'Μην ξεχάσετε να ρυθμίσετε τα ποσοστά κατανομής για την κέτωση κατάλληλα',
        ketoSetupCta: 'κάνοντας κλικ εδώ.',
    },
    generalMacroDist: 'Γενικά ποσοστά κατανομής μακροθρεπτικών συστατικών',
    enableDetailedDist: 'Ενεργοποίηση κατανομής μακροθρεπτικών για στοχοθέτηση κάθε γεύματος',
    distErrors: {
        total: 'Το άθροισμα της συνολικής κατανομής σας δεν είναι 100%',
        fats: 'Το άθροισμα του λίπους δεν είναι 100%',
        carbs: 'Το άθροισμα των υδατανθράκων δεν είναι 100%',
        proteins: 'Το άθροισμα των πρωτεϊνών δεν είναι 100%',
        ketoPlusFats: 'Όταν έχετε ενεργοποιημένη την κέτωση, δεν μπορεί το ποσοστό από τα λίπη να είναι κάτω από 60%',
        ketoPlusCarbs: 'Όταν έχετε ενεργοποιημένη την κέτωση, δεν μπορεί το ποσοστό από τους υδατάνθρακες να είναι πάνω από 10%'
    },
    attributes: 'Attributes',
    creation: {
        title: 'What kind of diet plan do you want to create?',
        custom: {
            title: 'Customizable plan',
            text: '(A diet plan with equations, foods, analysis etc)',
        },
        free: {
            title: 'Free-text plan',
            text: '(A diet plan that iss text only)'
        }
    }
}