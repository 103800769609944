import errors from "./errors";
import success from "./success";
import components from './components/index';
import diet_plan from "./diet_plan"
import routes from "./routes";
import pages from "./pages";
import global from "./global";
import forms from "./forms";
import educational from "./educational";
import topbar from "./topbar";
import autoGeneration from "./autoGeneration";
import dietPlans from "./dietPlans";
import dietPlan from "./dietPlan";
import food from "./food";
import footer from "./footer";
import auth from "./auth";
import chat from "./chat";
import tools from "./tools";
import cloud from "./cloud";
import calendar from "./calendar";
import pathisis from "./pathisis";
import massImport from "./massImport";
import admin from "./admin"
import billing from "./billing";
import shared from "./shared"
import inner from "./inner"

export default {
    topbar,
    errors,
    success,
    components,
    pages,
    diet_plan,
    routes,
    global,
    forms,
    educational,
    footer,
    dietPlans,
    dietPlan,
    autoGeneration,
    food,
    auth,
    chat,
    tools,
    cloud,
    calendar,
    pathisis,
    massImport,
    admin,
    billing,
    shared,
    inner,
    close: 'Close',
    dietitian: 'Dietitian',
    copied: 'Copied',
    minute: 'minute',
    minutes: 'minutes',
    next: 'Next',
    back: 'Back',
    click: 'click here',
    select: 'Select one',
    home: 'Home',
    today: 'Today',
    month: 'Month',
    week: 'Week',
    day: 'Day',
    timeGrid: 'Day',
    beta: 'Beta',
    save: 'Save',
    crop: 'Crop image',
    skipCrop: 'Skip cropping',
    saveChanges: 'Save changes',
    create: 'Create',
    notes: 'Notes',
    insertNotes: 'Insert notes...',
    datePickerPlaceholders: {
        date: "Select date:",
        range: "Select range",
        nextSeven: 'next 7 days',
        nextThirty: 'next 30 days',
        prevSeven: 'last 7 days',
        prevThirty: 'last 30 days'
    },
    optional: 'Optional',
    delete: 'Delete',
    search: 'Search',
    searchPlaceholder: 'Search...',
    actions: 'Actions',
    backToLastPage: 'Back to last page',
    verifyDeletion: {
        title: 'Are you sure you want to delete?',
        message: '<p>This action is non-recoverable. If you proceed with deletion, we will not be able to recover your data.' +
            '</p> <p><strong>Are you sure you want to proceed?</strong></p>',
        ok: 'Yes, delete',
        cancel: 'No, cancel'
    },
    cancel: 'Cancel',
    days: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
        short: {
            monday: 'Mon.',
            tuesday: 'Tu.',
            wednesday: 'Wed.',
            thursday: 'Thur.',
            friday: 'Fri.',
            saturday: 'Sat.',
            sunday: 'Sun.',
        }
    },
    meals: {
        breakfast: 'Breakfast',
        midBreakfast: 'Snack',
        lunch: 'Lunch',
        afterLunch: 'Snack',
        dinner: 'Dinner'
    },
    counts: {
        first: '1st',
        second: '2nd',
        third: '3rd',
        fourth: '4th',
    },
    nthChoice: '{num}st Choice',
    nthChoiceShort: '{num}th choice',
    months: {
        short: {
            january: 'Jan',
            february: 'Feb',
            march: 'Mar',
            april: 'Apr',
            may: 'May',
            june: 'Jun',
            july: 'Jul',
            august: 'Aug',
            september: 'Sep',
            october: 'Oct',
            november: 'Nov',
            december: 'Dec'
        },
    },
    name: 'Name',
    templateName: 'Template name',
    short: {
        quantity: 'Qt'
    },
    quantity: 'Quantity',
    micro: {
        carbs: 'Carbohydrates',
        proteins: 'Proteins',
        fats: 'Fats',
        cal: 'Energy',
        percentage: {
            carbs: 'Carbohydrates percentage',
            proteins: 'Proteins percentage',
            fats: 'Fat percentage',
        },
        short: {
            carbs: 'Carbs',
            proteins: 'Prot.',
            fats: 'Fats',
            cal: 'Energ.',
        }
    },
    show: 'Show',
    hide: 'Hide',
    date: 'Date',
    google_calendar_redirect: {
        title: 'This page is only accessible by your dietitian',
        text: 'The link you followed is only available to your dietitian.' +
            'If you wish to use our mobile app, please ask your dietitian to share the instructions on how to set it up!',
        dietitian: 'Are you a dietitian trying to login?',
        button: 'Click here to sign in'
    },
    shareACloud: 'Share to aCloud',
    aCloudCantReShare: 'You cannot share this again to athlisis cloud.',
    aCloudViewLive: 'View cloud version',
    copy: 'Copy',
    paste: 'Paste',
    clear: 'Clear',
    editMeal: 'Edit meal',
    copyText: 'Copy to new',
    list: 'List',
    foods: 'Foods',
    mealTemplates: {
        title: 'Meal Templates',
        kind: 'Kind',
        free: 'Free template',
        chooseType: 'Choose type',
        chooseKind: 'Choose kind',
        empty: 'NO MEAL TEMPLATES FOUND',
        emptyText: 'You have not saved any meal templates.'
    },
    chooseCustomer: 'Choose client',
    copyToTemplate: 'Copy to new template',
    copyToAthlete: 'Copy to client',
    copyTemplate: 'Copy template',
    saveTemplate: 'Save as template',
    importTemplate: 'Import from template',
    sendByMail: 'Send by e-mail',
    emailSubject: 'E-mail subject',
    description: 'Description',
    aCloudName: 'aCloud\'s name',
    chooseTagsACloud: 'Select tags (at least 1)',
    reset: 'Reset',
    total: 'Total',
    saveAsCsv: 'Save as CSV',
    isOffline:'You have no internet connection',
    backOnline: 'Your internet connection has been recovered',
    sent: 'Sent',
    safariInstructions: 'Safari users should, <a\n' +
        '        href="https://athlisis-new.s3.eu-north-1.amazonaws.com/athlisis_safari_print_e2dbe5442e.pdf" target="_blank"\n' +
        '    >\n' +
        '      read the following instructions by clicking here.\n' +
        '    </a>',
    tbw: 'TBW (Total Body Water)',
    weeklyExercise: 'Weekly Exercise',
    templatePlan: 'Diet plan Template',
    notifications: {
        title: 'Notifications',
        noNotifications: 'There are no notifications!'
    },
    eg: 'e.g. {value}',
    measure: 'Measurement',
    measures: {
        title: 'Measures',
        0: 'Choose one',
        short: {
            1: 'pc. (lg)',
            2: 'pc. (sm.)',
            3: 'pc.',
            4: 'cup',
            6: 'teaspoon',
            7: 'tablespoon',
            8: 'gr.',
            9: 'φλ. τσ.',
            10: 'pc. (med.)',
            11: 'ml',
            12: 'scoop'
        },
        long: {
            1: 'Piece (large)',
            2: 'Piece (small)',
            3: 'Piece',
            4: 'Cup',
            6: 'Teaspoon',
            7: 'Tablespoon',
            8: 'Grammars',
            9: 'Teacup',
            10: 'Piece (medium)',
            11: 'ml',
            12: 'Scoop'
        }
    }
}
