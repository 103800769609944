export default {
    title: 'Διατροφικό πλάνο',
    auto: 'Αυτόματη δημιουργία',
    previewMacros: 'Προεπισκόπηση μακροθρεπτικών γευμάτων',
    findDislikes: 'Εύρεση τροφίμων που δεν αρέσουν στον πελάτη',
    findDislikesDisclaimer: 'Η εύρεση δεν λειτουργεί με ακρίβεια και ενδέχεται να έχει εσφαλμένες ενδείξεις ή ελλείψεις.',
    recipes: 'Συνταγές διατροφικού πλάνου',
    share: 'Αποστολή με e-mail / Αποθήκευση ως PDF',
    searchFor: 'Αναζήτηση για',
    autoDesc: 'Αυτόματη δημιουργία διατροφικού πλάνου',
    system: 'Πρότυπο διατροφικό πλάνο του συστήματος, δεν μπορείτε να το επεξεργαστείτε. Αντιγράψτε το σε πελάτη ή σε νέο.',
    copyToTemplateTitle: 'Θέλετε σίγουρα να αποθηκεύσετε το πλάνο ως πρότυπο;',
    copyToTemplateText: 'Θα δημιουργηθεί ένα ακριβές αντίγραφο του διατροφικού πλάνου το οποίο μπορείτε να επεξεργαστείτε ξεχωριστά.',
    copyToAthleteTitle: 'Θέλετε σίγουρα να αντιγράψετε το διατροφικό πλάνο;',
    copyToAthleteText: 'Θα δημιουργηθεί ένα ακριβές αντίγραφο του διατροφικού πλάνου το οποίο μπορείτε να επεξεργαστείτε ξεχωριστά.',
    noCalories: {
        title: 'Δεν έχετε εισάγει τις θερμίδες και τα μακροθρεπτικά στοιχεία του πλάνου.',
        cta: 'Συμπληρώστε τις εξισώσεις ή εισάγετε χειροκίνητα!'
    },
    replaceCustomerFns: 'Αντικατάσταση ύψους/βάρους του πλάνου με του πελάτη;',
    addToCustomer: 'Προσθήκη σε πελάτη',
    pasteWithoutClear: 'Επικόλληση χωρίς εκκαθάριση',
    copyDay: 'Αντιγραφή ημέρας',
    pasteDay: 'Επικόλληση ημέρας',
    clearDay: 'Εκκαθάριση ημέρας',
    carbohydrationWarning: 'Έχετε ενεργοποιημένη την υδατανθράκωση!',
    ketosisWarning: 'Έχετε ενεργοποιημένη την κέτωση!',
    copyToTable: 'Αντιγραφή πίνακα (σε word, excel)',
    mealType: 'Τύπος γεύματος',
    insulinUnits: 'Μονάδες ινσουλίνης',
    recipeModal: {
        title: 'Λίστα συνταγών στο διατροφικό πλάνο',
        list: 'Λίστα συνταγών',
        empty: 'Δεν βρέθηκαν συνταγές σε αυτό το διατροφικό πλάνο!'
    },
    equations: {
        exerciseWarning: 'Οι θερμίδες που αναγράφονται δεν περιλαμβάνουν τα αθλήματα και αφορούν τον βασικό μεταβολισμό επί 1.2!',
        lowCalIntakeWarning: 'ΠΡΟΣΟΧΗ! Η πρόσληψη ενέργειας δεν πρέπει ποτέ να είναι μικρότερη από τον βασικό μεταβολισμό',
        title: 'Εξισώσεις',
        bmr: 'Βασικός Μεταβολισμός (kcal)',
        kcal: 'Απαραίτητη ενέργεια (kcal)',
        exerciseLevels: {
            title: 'Επιλέξτε επίπεδο άσκησης',
            '13': '(1.00-1.39) Τυπικές καθημερινές δραστηριότητες (Οικιακά καθήκοντα, χαλαρό περπάτημα,\n' +
                '                  καθιστική εργασία)',
            '155': '(1.4-1.59) Χαμηλή δραστηριότητα, τυπικές καθημερινές δραστηριότητες και 30-60 λεπτά\n' +
                '                  καθημερινής μέτριας δραστηριότητας (περπάτημα με 5-7 χλμ. / Ώρα)',
            '17': '(1.6-1.89) Ενεργοί άνθρωποι, τυπικές καθημερινές δραστηριότητες συν 60 λεπτά\n' +
                '                  καθημερινής μέτριας δραστηριότητας',
            '19': '(1.9-2.5) Πολύ δραστήριοι, τυπικές καθημερινές δραστηριότητες συν τουλάχιστον 60\n' +
                '                  λεπτά καθημερινής μέτριας δραστηριότητας συν επιπλέον 60 λεπτά έντονης ή 120 λεπτά μέτριας\n' +
                '                  δραστηριότητας'
        },
        pal: {
            title: 'Χειροκίνητος συντελεστής PAL',
            range: 'Εύρος: από {from} έως {to}'

        },
        placeholder: {
            age: 'Εισάγετε την ηλικία του πελάτη',
            height: 'Εισάγετε το ύψος του πελάτη σε cm',
            weight: 'Εισάγετε το βάρους του πελάτη σε kg',
            fat: 'Εισάγετε το λίπος του πελάτη (π.χ. 10%)',
            fatPerKg: 'Λίπος ανά κιλό σωματικού βάρους',
            proteinsPerKg: 'Πρωτεϊνες ανά κιλό σωματικού βάρους',
            carbsPerKg: 'Υδατάνθρακες ανά κιλό σωματικού βάρους'
        },
        errors: {
            age: 'Η ηλικία πρέπει να είναι έγκυρη',
            height: 'Το ύψος πρέπει να είναι έγκυρο',
            weight: 'Το βάρος πρέπει να είναι έγκυρο',
            fat: 'Το λίπος πρέπει να είναι έγκυρο',
        },
        settings: {
            title: 'Ρυθμίσεις',
            manualKcal: 'Εισαγωγή θερμίδων χειροκίνητα;',
            advancedEq: 'Προχωρημένη εξίσωση; (χρήση αθλημάτων για υπολογισμό ενέργειας ανά ημέρα)',
            manualBmr: 'Εισαγωγή βασικού μεταβολισμού (BMR) χειροκίνητα;',
            exerciseFactor: 'Υπολογισμός θερμιδικών αναγκών με συντελεστή άθλησης',
        },
        goal: {
            title: 'Επιλέξτε στόχο',
            maintain: 'Συντήρηση',
            add: 'Μείωση βάρους',
            lose: 'Αύξηση βάρους',
            kgLose: 'Πόσα κιλά θέλετε να μειωθούν ανά μήνα;',
            kgAdd: 'Πόσα κιλά θέλετε να προστεθούν ανά μήνα;'
        },

        functions: {
            title: 'Επιλέξτε συνάρτηση',
            1: 'Harris & Benedict',
            2: 'Αναθεωρημένη Harris & Benedict',
            3: 'Owen',
            4: 'Miffin',
            5: 'Katch-McArdle',
            6: 'Cunningham',
            7: 'FAO/WHO/UNU'
        }
    },
    macroTab: {
        title: 'Κατανομή μακροθρεπτικών',
        track: 'Ενεργοποίηση παρακολούθησης μικροθρεπτικών;',
        choose: 'Παρακαλώ επιλέξτε ποια μικροθρεπτικά επιθυμείτε να παρακολουθήσετε στο παράθυρο γεύματος',
        general: 'Γενικά μικροθρεπτικά',
        trixopoulou: 'Μικροθρεπτικά μόνο στη λίστα Τριχοπούλου',
        usda: 'Μικροθρεπτικά μόνο στη λίστα USDA',
    },
    exerciseTab: {
        title: 'Άσκηση',
        warning: 'Για να επιλέξετε αθλήματα, πρέπει να έχετε ενεργοποιημένη την προχωρημένη εξίσωση.',
        warningCta: 'Αλλαγή ρυθμίσεων'
    },
    settingsTab: {
        title: 'Ρυθμίσεις'
    },
    analysisTab: {
        title: 'Ανάλυση'
    },
    notesTab: {
        insertToNotes: 'Εισαγωγή στις σημειώσεις',
        replaceContent: 'Αντικατάσταση περιεχομένου;',
        intro: 'Εισάγετε σημειώσεις για το διατροφικό πλάνο που μπορείτε να χρησιμοποιήσετε κατά την εκτύπωση',
    },
    settings: {
        optionsVsDays: 'Προβολή <strong>επιλογών</strong> έναντι ημερών;',
        mobileShare: 'Κοινοποίηση σε Mobile εφαρμογή;',
        newFreeType: 'Ενεργοποίηση ελεύθερου πλάνου επιλογών',
        isVegan: 'Vegan διατροφολόγιο',
        isFasting: 'Νηστεία',
        isKeto: 'Κετογονική διατροφή',
        isInsulin: 'Ινσουλινοεξαρτώμενος διαβήτης',
        insulinRatio: 'Εισάγετε την αναλογία υδατάνθρακα για κάθε μονάδα ινσουλίνης. Π.χ. για 1:15 εισάγετε 15',
        insulinRatioWarning: 'Μην ξεχάσετε να ρυθμίσετε την αναλογία ινσουλίνης:υδατάνθρακα.\n' +
            '                  Συμβουλευτείτε τις οδηγίες του ιατρού του διαιτώμενου σχετικά με την αναλογία. Μπορείτε να μάθετε\n' +
            '                  περισσότερες πληροφορίες στις οδηγίες για διατροφολόγους του Pathisis.',
        carbohydration: 'Υδατανθράκωση',
        dayOfCarbohydration: 'Επιλέξτε ημέρα υδατανθράκωσης',
        carbohydrationMissingWeight: 'Δεν έχετε εισάγει το βάρος του πελάτη ώστε να λειτουργήσει ορθά η υδατανθράκωση.',
        carbohydrationMissingWeightCta: 'Κάντε κλικ εδώ για να το εισάγετε!',
        ketoSetup: 'Μην ξεχάσετε να ρυθμίσετε τα ποσοστά κατανομής για την κέτωση κατάλληλα',
        ketoSetupCta: 'κάνοντας κλικ εδώ.',
    },
    generalMacroDist: 'Γενικά ποσοστά κατανομής μακροθρεπτικών συστατικών',
    enableDetailedDist: 'Ενεργοποίηση κατανομής μακροθρεπτικών για στοχοθέτηση κάθε γεύματος',
    distErrors: {
        total: 'Το άθροισμα της συνολικής κατανομής σας δεν είναι 100%',
        fats: 'Το άθροισμα του λίπους δεν είναι 100%',
        carbs: 'Το άθροισμα των υδατανθράκων δεν είναι 100%',
        proteins: 'Το άθροισμα των πρωτεϊνών δεν είναι 100%',
        ketoPlusFats: 'Όταν έχετε ενεργοποιημένη την κέτωση, δεν μπορεί το ποσοστό από τα λίπη να είναι κάτω από 60%',
        ketoPlusCarbs: 'Όταν έχετε ενεργοποιημένη την κέτωση, δεν μπορεί το ποσοστό από τους υδατάνθρακες να είναι πάνω από 10%'
    },
    attributes: 'Ιδιότητες',
    creation: {
        title: 'Τι είδους διατροφικό πλάνο θέλετε να δημιουργήσετε;',
        custom: {
            title: 'Εξατομικευμένο πλάνο',
            text: '(Διατροφικό πλάνο με εξισώσεις, τρόφιμα, αναλύσεις κ.λπ.)',
        },
        free: {
            title: 'Ελεύθερο πλάνο',
            text: '(Διατροφικό πλάνο μόνο με κείμενο)'
        }
    }
}