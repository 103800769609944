<template>
  <!-- Topbar Start -->
  <div id="topbar" class="navbar-custom px-0">
    <div class="container-fluid pr-0 d-flex align-items-center justify-content-between">
      <!-- LOGO -->
      <div class="d-flex align-items-center flex-shrink-0">
        <div class="logo-box text-center">
          <router-link class="logo flex-shrink-0"  to="/">
            <span class="logo-lg">
              <img alt height="28" src="@assets/images/athlisis-logo.svg"/>
            </span>
              <span class="logo-sm mr-3">
              <img alt height="28" class="" src="@assets/images/athlisis-logo-sm.svg"/>
            </span>
          </router-link>
        </div>
        <b-collapse id="navigation" v-model="isMenuOpened">
          <!-- Navigation Menu-->
          <ul class="navigation-menu">
            <li v-for="item in menu" :key="item.name" :class="item.hasSubmenu ? 'has-submenu' : ''">
              <router-link :to="item.url" tag="a">
                <img v-if="item.icon && item.icon.includes('/img/')" :src="item.icon" class="menu-img"/>
                <i v-else :class="item.icon"></i>
                <span class="hide-tablet">{{ item.name }}</span>
              </router-link>
              <ul v-if="item.hasSubmenu" class="submenu megamenu">
                <li class="w-100">
                  <ul>
                    <li v-for="sub in item.subMenu" :key="'sub-'+sub.name">
                      <router-link :to="sub.url" class="side-nav-link-ref" tag="a">
                        <i :class="sub.icon"></i>
                        {{ sub.name }}
                      </router-link>
                    </li>
                    <li v-if="item.url === '/athletes'">
                      <a style="cursor:pointer" @click="$refs['create-client'].show()"><i class="fe-plus-square"></i>
                        {{ $t('topbar.athletes.quickAdd') }}</a>
                    </li>
                  </ul>
                </li>
              </ul>
            </li>
<!--            <li v-if="user && user.isAdmin">-->
<!--              <router-link class="side-nav-link-ref admin-foods-link bg-light" tag="a" to="/admin/users"><i-->
<!--                  class="fe-settings"-->
<!--              ></i>Admin-->
<!--              </router-link>-->
<!--            </li>-->
          </ul>
        </b-collapse>
      </div>

      <ul class="list-unstyled topnav-menu d-flex align-items-center justify-content-end mb-0">
        <li class="dropdown notification-list">
          <a :class="{ 'open': isMenuOpened }" class="navbar-toggle nav-link" @click="toggleMenu">
            <div class="lines">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </a>
        </li>

        <li class="d-none d-sm-block b-nav-dropdown dropdown">
          <div class="app-search">
            <div class="app-search-box">
              <div class="input-group">
                <div class="input-group-prepend">
                  <button class="btn">
                    <img v-if="!fetchingResults" src="@assets/icons/search-icon.svg"/>
                    <img v-else alt="loading-icon" height="20px" src="@assets/images/loading.svg"/></button>
                </div>
                <b-form-input id="search-athletes"
                              v-model="searchKeyword" :placeholder="$t('searchPlaceholder')" class="form-control"
                              debounce="700"
                              type="text"
                              @focus="showSearchResults"
                              @input="fetchingResults = true"
                />
              </div>
            </div>
          </div>
          <ul v-click-outside="hideSearchResults" :class="showAthleteList ? 'show' :''"
              class="dropdown-menu dropdown-menu-right" style="min-width:300px;"
          >
            <b-dropdown-text class="p-0">
              <span class="b-dropdown-text"><h5 class="m-0 pt-2 pb-2 ">{{ $t('topbar.searchResults') }}</h5></span>
              <!--                </li>-->
              <div v-if="fetchingResults">
                <img alt="loading-icon" height="50px" src="@assets/images/loading.svg"/>
              </div>
              <div v-else class="ps-container ps">
                <div v-if="showAthleteList && athletes && athletes.length > 0" class="noti-scroll">
                  <!-- items-->
                  <div v-for="athlete in getAthletes" :key="athlete.id" class="dropdown-item notify-item text-link"
                       @click="redirectTo(`/athletes/${athlete.id}`)"
                  >

                    <p class="notify-details">{{ athlete.firstName }} {{ athlete.lastName }}<br><small
                        class="text-muted"
                    >{{ $t('pages.home.birthdays.phone') }}: {{ athlete.mobileNum }}</small></p>

                  </div>

                </div>
              </div>
              <a v-if="notifications && notifications.length > 0"
                 class="dropdown-item text-center text-primary notify-item notify-all text-link"
                 @click="$router.push({ path: '/athletes', query: { search: searchKeyword } } )"
              >
                {{ $t('global.viewAll') }}<span class="fe-arrow-right"/></a>

            </b-dropdown-text>
          </ul>
        </li>

        <li :class="$route.name && $route.name.includes('cloud') ? 'show' : ''"
            class="nav-link dropdown-toggle notification-list calendar-btn" @click="openCloudModal"
        >
        </li>

        <li v-if="$route.name" class="nav-link dropdown-toggle">
          <language-switcher/>
        </li>

        <li :class="$route.name && $route.name.includes('cloud') ? 'show' : ''"
            class="nav-link dropdown-toggle notification-list calendar-btn" @click="openCloudModal"
        >
          <div>
            <img class="topbar-right-icon mr-xl-1" src="@assets/icons/cloud-icon-1.svg"/> <span class="hide-mobile">
            {{ $t('topbar.aCloud') }}
          </span>
          </div>

          <!--          <div  class="topbar-right-icon">Cloud</div>-->
        </li>
        <li :class=" $route.name && $route.name.includes('chat') ? 'show' : ''"
            class="nav-link dropdown-toggle notification-list calendar-btn chat-btn" @click="openChat"
        >
          <div>
            <img src="@assets/icons/chat.svg" width="19"/>

          </div>
          <b-badge v-if="unreadChats > 0" class="rounded-circle noti-icon-badge" pill variant="danger">
            {{ unreadChats }}
          </b-badge>
        </li>
        <li :class="$refs['tools-modal'] && $refs['tools-modal'].$data.isShow ? 'show' : ''"
            class="nav-link dropdown-toggle notification-list calendar-btn" @click="openToolsModal"
        >
          <img class="topbar-right-icon" src="@assets/icons/settings-icon.svg"/>
        </li>


        <li :class="$refs['pathisis-modal'] && $refs['pathisis-modal'].$data.isShow ? 'show' : ''"
            class="nav-link dropdown-toggle notification-list calendar-btn pathisis-btn"
            @click="openPathisisModal"
        >
          <img class="topbar-right-icon" src="@assets/icons/pathisis-icon.svg"/>
        </li>

        <li :class=" $route.name === 'calendar' ? 'show' : ''"
            class="nav-link dropdown-toggle notification-list calendar-btn "
            @click="$router.push( { name: 'calendar' } )"
        >
          <img class="topbar-right-icon" src="@assets/icons/calendar-icon.svg"/>
        </li>
        <!-- notification list -->
        <b-nav-item-dropdown class="notification-list hide-mobile" right>
          <template slot="button-content" class="nav-link dropdown-toggle">
            <img class="topbar-right-icon" src="@assets/icons/notification-icon.svg"/>
            <span v-if="notificationCount > 0" class="badge badge-danger rounded-circle noti-icon-badge"
            >{{ notificationCount }}</span>
          </template>

          <b-dropdown-text class="dropdown-item noti-title" href="#">
            <h5 class="m-0"><span v-if="notificationCount > 0" class="float-right">
              <a
                  class="text-dark text-link"
                  @click="markAllAsRead"
              ><small>{{ $t('topbar.markAsRead') }}</small></a></span>
              {{ $t('topbar.news.title') }}
            </h5>

          </b-dropdown-text>

          <b-dropdown-text class="p-0" href="#" style="min-width:300px;">
            <VuePerfectScrollbar class="noti-scroll">
              <div v-if="notifications && notifications.length < 1" class="text-center" style="margin-top:-30px;">
                <img alt="" class="img-elements-not-found" height="100" src="@assets/images/undraw-empty.svg"/>
                <div class="text-center">
                  <h5 class="mt-3 text-uppercase font-weight-bold">{{ $t('topbar.news.noNews') }}</h5>
                </div>
              </div>
              <!-- items-->
              <a v-for="nf of notifications" :key="nf.id" class="dropdown-item notify-item text-link"
                 @click="$router.push({ name: 'user-notifications' })"
              >
                <div class="notify-icon bg-primary"><i class="icon-bell"></i></div>
                <b-badge v-if="!nf.isRead" variant="danger">{{ $t('topbar.unread') }}</b-badge>
                <p class="notify-details">{{ nf.title }}<small class="text-muted">{{ $t('date') }}: {{
                    nf.updatedAt
                  }}</small>
                </p>
              </a>

            </VuePerfectScrollbar>

            <a v-if="notifications && notifications.length > 0"
               class="dropdown-item text-center text-primary notify-item notify-all text-link"
               @click="$router.push({ name: 'user-notifications' })"
            >
              {{ $t('global.viewAll') }} <span class="fe-arrow-right"/> </a>

          </b-dropdown-text>
        </b-nav-item-dropdown>

        <b-nav-item-dropdown class="notification-list flex-shrink-0" menu-class="profile-dropdown" right>
          <template slot="button-content">
            <div class="nav-user mr-0">
              <img v-if="user"
                   :src="user.image ? user.image : 'https://www.gravatar.com/avatar/1db7993cb4a4a74ce58f4a6926cec867?s=80&d=mp&r=g'"
                   class="rounded-circle"
              />

              <span class="pro-user-name ml-1">
                <span class="icon-arrow-down " style="font-size:8px;color:black"></span>
              </span>
            </div>
          </template>

          <b-dropdown-item to="/user">
            <i class="fe-user"></i><span>{{ $t('topbar.account') }}</span>
          </b-dropdown-item>

          <b-dropdown-divider></b-dropdown-divider>

          <b-dropdown-item to="/logout">
            <i class="fe-log-out mr-1"></i><span>
            {{ $t('topbar.logout') }}
          </span>
          </b-dropdown-item>

        </b-nav-item-dropdown>

      </ul>


    </div>

    <b-modal id="tools-modal" ref="tools-modal" hide-footer size="xl"
             :title="$t('topbar.scienceTools')">
      <tools-base/>
    </b-modal>

    <b-modal id="pathisis-modal" ref="pathisis-modal" hide-footer scrollable size="xl" static title="Pathisis">
      <pathisis-base/>
    </b-modal>

    <b-modal id="create-client" ref="create-client" hide-footer size="md"
             :title="$t('topbar.athletes.quickAdd')">
      <NewCustomerModal @clientCreated="clientCreated"/>
    </b-modal>
  </div>
  <!-- end Topbar -->
</template>
<script>
import {authComputed} from '@state/helpers'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import axios from 'axios'
import {mapState} from "vuex";
import toolsBase from '@components/tools/tools-base'
import NewCustomerModal from "@components/global/NewCustomerModal";
import pathisisBase from "@components/pathisis/pathisis-base"
import vClickOutside from 'v-click-outside'
import Empty from "@components/global/Empty.vue";
import LanguageSwitcher from "@components/global/LanguageSwitcher.vue";

export default {

  components: {LanguageSwitcher, Empty, VuePerfectScrollbar, toolsBase, NewCustomerModal, pathisisBase},
  directives: {
    clickOutside: vClickOutside.directive
  },
  props: {
    isMenuOpened: {
      type: Boolean,
      required: false,
      default: false,
    },
  },

  data() {
    return {
      notifFetched: false,
      fetchingResults: false,
      searchKeyword: '',
      timer: null,
      athletes: [],
      showAthleteList: false,
      menu: [
        {
          name: this.$t('topbar.dashboard'),
          icon: require('@assets/icons/dashboard-icon.svg'),
          url: '/'
        },
        {
          name: this.$t('topbar.athletes.title'),
          icon: require('@assets/icons/clients-icon.svg'),
          url: '/athletes',
          hasSubmenu: true,
          subMenu: [
            {
              name: this.$t('topbar.athletes.all'),
              icon: 'fe-list',
              url: '/athletes'
            },
            {
              name: this.$t('topbar.athletes.statistics'),
              icon: 'fe-pie-chart',
              url: '/athletes/statistics'
            },
            {
              name: this.$t('topbar.athletes.new'),
              icon: 'fe-plus-circle',
              url: '/athletes/add'
            },
          ]
        },
        {
          name: this.$t('topbar.foods'),
          icon: require('@assets/icons/foods-icon.svg'),
          url: '/foods'
        },
        {
          name: this.$t('topbar.dietPlans.title'),
          icon: require('@assets/icons/dp-icon.svg'),
          url: '/diet-plans',
          hasSubmenu: true,
          subMenu: [
            {
              name: this.$t('topbar.dietPlans.all'),
              icon: 'fe-list',
              url: '/diet-plans'
            },
            {
              name: this.$t('topbar.dietPlans.templates'),
              icon: 'fe-bookmark',
              url: '/meals'
            },
            {
              name: this.$t('topbar.dietPlans.statistics'),
              icon: 'fe-pie-chart',
              url: '/diet-plans/statistics'
            },
            {
              name: this.$t('topbar.dietPlans.printTemplates'),
              icon: 'icon-printer',
              url: '/user/print-templates'
            },
            {
              name: this.$t('topbar.dietPlans.noteTemplates'),
              icon: 'icon-notebook',
              url: '/diet-plans/notes'
            },
            {
              name: this.$t('topbar.dietPlans.recipes'),
              icon: 'icon-book-open',
              url: '/recipes'
            }
          ]
        },
      ],
    }
  },

  computed: {
    ...authComputed,
    ...mapState({
      user: state => state.auth.currentUser,
      notifications: state => state.notifications.notifications,
      notificationCount: state => state.notifications.notificationsCount,
      unreadChats: state => state.chat.unreadChats
    }),
    getAthletes() {
      return this.athletes
    }
  },

  created() {
    this.$store.dispatch('notifications/refreshCacheNotifications');
  },
  watch: {
    searchKeyword(val) {
      this.fetchAthletes();
    },
    '$route.path'(){
      if(this.isMenuOpened) this.toggleMenu()
    }
  },

  methods: {
    clientCreated() {
      this.$refs['create-client'].hide();
    },
    openToolsModal() {
      this.$refs['tools-modal'].show();
    },
    openPathisisModal() {
      this.$refs['pathisis-modal'].show();
    },
    openCloudModal() {
      this.$router.push({name: 'cloud'});
    },
    openChat() {
      this.$router.push({name: 'chat'});
    },
    toggleMenu() {
      this.$parent.toggleMenu()
    },

    markAllAsRead() {
      axios.put(`${process.env.VUE_APP_API}/notifications/markAllAsRead`).then(result => {
        this.$store.dispatch('notifications/cacheNotifications');
        this.$notify({
          group: 'athlisis-notifications',
          type: 'success',
          title: this.$t('success.title'),
          text: this.$t('success.text')
        });
      }).catch(e => {
        this.$notify({
          group: 'athlisis-notifications',
          type: 'error',
          duration: 5000,
          title: this.$t('errors.title'),
          text: this.$t('errors.text')
        });
      });
    },
    fetchAthletes() {

      if (this.searchKeyword === "" || !this.searchKeyword) {
        this.searchKeyword = null;
        this.athletes = [];
        this.hideSearchResults();
        return;
      }

      const params = {
        limit: 10,
        offset: 0,
        search: this.searchKeyword,
      };

      this.$axios.get(`/athlete/`, {params: params}).then(result => {
        this.fetchingResults = false;
        this.athletes = result.data.rows;
        this.showSearchResults();

      }).catch(e => {
        this.$notify({
          group: 'athlisis-notifications',
          type: 'error',
          duration: 5000,
          title: this.$t('errors.title'),
          text: this.$t('errors.text')
        });
      });


    },
    hideSearchResults(event) {
      this.fetchingResults = false;
      if (event && event.target && event.target.id === 'search-athletes') {
        return;
      }
      this.showAthleteList = false;
    },

    showSearchResults() {
      if (this.searchKeyword === "" || this.searchKeyword == null) return this.hideSearchResults();
      this.showAthleteList = true;
    },
    redirectTo(route) {
      this.hideSearchResults();
      this.$router.push(route);
    }

  },
}
</script>



<style lang="scss">
#topbar {
  .tools-dropdown {
    width: 270px;
  }

  .calendar-btn {
    position: relative;
    cursor: pointer;

    &.show {
      background-color: $color_light_bg
    }
  }
}

.pathisis-btn {
  @media(max-width: 767px) {
    display: none !important;
  }
}

.hide-mobile {
  @media(max-width: 1024px) {
    display: none;
  }
}

.hide-tablet {
  @media(max-width: 1350px) and (min-width: 920px) {
    display: none;
  }
}

.noti-scroll {
  height: 220px;
}

.ps > .ps__scrollbar-y-rail {
  width: 8px !important;
  background-color: transparent !important;
}

.ps > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps.ps--in-scrolling.ps--y > .ps__scrollbar-y-rail > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:active > .ps__scrollbar-y,
.ps > .ps__scrollbar-y-rail:hover > .ps__scrollbar-y {
  width: 6px !important;
}

.button-menu-mobile {
  outline: none !important;
}

.text-link {
  cursor: pointer;
}

.noti-title {
  width: 325px;
}
</style>
